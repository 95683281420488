<template>
    <div class="pathology">
        <div class="title_box">
            <p class="title3">微量物证鉴定</p>
            <p class="title_tip3">TRACE EVIDENCE IDENTIFICATION</p>
        </div>
        <div class="center_content bold">
            《物证类司法鉴定执业分类规定》司法部司规［2020］5号
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            微量物证鉴定简称微量鉴定，是指鉴定人运用理化检验的原理、方法或专门知识，使用专门的分析仪器，对物质的物理性质、化学性质和成分组成进行检验检测和分析判断并提供鉴定意见的活动。

        </div>
        <div style="margin:2rem 0" class="flex wrap">
          <div class="col3 flex center" style="margin-bottom:2rem;" v-for="(item,index) in data" :key="index">
            <div class="col5 hvr-forward">
              <div style="margin-bottom:1rem;">
                <img :src="require('../../assets/range/trace_evidence/'+item[0])">
              </div>
              <p> 
                {{(index-0+1)+'.'+item[1]}}
              </p>
            </div>
          </div>
        </div>
        
        <ConnectBtn></ConnectBtn>
    </div>
</template>
<script>
export default {
  data(){
    return{
      data:[
        ['1-1.jpg','化工产品类鉴定'],
        ['1-2.jpg','金属和矿物类鉴定'],
        ['1-3.jpg','纺织品类鉴定'],
        ['1-4.jpg','日用化学品鉴定'],
        ['1-5.jpg','文化用品类鉴定'],
        ['1-6.jpg','食品类鉴定'],
        ['1-7.jpg','爆炸物类鉴定'],
        ['1-8.jpg','易燃物质类鉴定'],
        ['1-9.jpg','射击残留物类鉴定'],
        ['1-10.jpg','交通事故微量物证鉴定'],
        ['1-11.jpg','火灾微量物证鉴定'],
      ]
    }
  }
}
</script>